var officeBk = (function (settings) {
	'use strict';

	var userTokenMap = {};
	var userProfile;
	var userList;
	var preventUserListFor = {};
	var includeGroupCalendars = {};
	var baseURL = _CONFIG.DBK_BASEURL || `//${window.location.host}/`;
	var maxPageValue = 999;

	var errorReporter;

	var tokenWatcher;

	var activeEventQueries = 0;

	var lastFetchID;

	return {
		clearTokenMemory: clearTokenMemory,
		setErrorReporter: setErrorReporter,

		auth: auth,
		deauthorize: deauthorize,
		getUserProfile: getUserProfile,
		calendarList: calendarList,
		eventList: eventList,
		updateCalendar: updateCalendar,

		getUsers: getUsers,

		updateEvent: updateEvent,
		createEvent: createEvent,
		deleteEvent: deleteEvent,
		getTasks: getTasks,
		isUser: isUser,
		viewInOffice: viewInOffice,
		storage: settings,
		userLocationByName: userLocationByName,
		getEvent: getEvent,
		getEventInstances: getEventInstances,
		ajaxRequest: ajaxRequest,
	};

	/*############PUBLIC FUNCTIONS###############*/

	function clearTokenMemory() {
		settings.token = null;
		settings.config.immediate = false;
	}

	function setErrorReporter(errorReportingFunction) {
		errorReporter = errorReportingFunction;
	}

	function getUserProfile() {
		return userProfile;
	}

	function isSalesforce() {
		return fbk.isSalesforce();
	}

	//Google Authorization routine.
	//Try to get the token silently, if that fails then callback should present button for auth - statusOnly is boolean to avoid an attempt to sign in
	function auth(options) {
		const useOptions = options && typeof options === 'object';

		// moving to options object but leaving arguments for backward compatibility in actions
		const userID = useOptions ? options.userID : arguments[0];
		const sourceID = useOptions ? options.sourceID : arguments[1];
		const statusOnly = useOptions ? options.statusOnly : arguments[2];
		const forceConsent = useOptions ? options.forceConsent : arguments[3];
		const redirectAuth = useOptions ? options.redirectAuth : arguments[4];
		const redirectAuthFunction = useOptions
			? options.redirectAuthFunction
			: arguments[5];
		const preventUserList = useOptions
			? options.preventUserList
			: arguments[6];
		const callback = useOptions ? options.callback : arguments[7];
		const includeGroupSchedules = useOptions
			? options.includeGroupCalendars
			: arguments[8];

		// var forceRedirect = (redirectAuth || isStandalone()) && !isSalesforce();
		var forceRedirect = redirectAuth && !isSalesforce();
		var salesforceMobileRedirect = isMobile() && isSalesforce();

		preventUserListFor[sourceID] = preventUserList;
		includeGroupCalendars[sourceID] = includeGroupSchedules;

		if (statusOnly) {
			settings.config.immediate = true;
		}

		if (forceConsent) {
			settings.config.immediate = false;
		}

		if (!settings.config.immediate && forceRedirect) {
			authRedirect(sourceID, userID, function (result) {
				check(result, sourceID, callback);
			});
		}
		// else if (!settings.config.immediate && salesforceMobileRedirect) {
		//Enable this if there is no way to tell the difference between the SF mobile app and the SF1 Web page
		// 	salesforceMobileAuthRedirect(sourceID, userID, callback);
		// }
		else if (!settings.config.immediate) {
			authPopup(sourceID, userID, function (result) {
				check(result, sourceID, callback);
			});
		} else if (!settings.token) {
			// Attempt to get new token from server
			updateToken(userID, sourceID, function (result) {
				check(result, sourceID, callback);
			});
		} else {
			callback(true);
		}

		function authRedirect(sourceID, userID, callback) {
			const currentURL = window.location.href;
			let redirectURL =
				baseURL +
				'api/office/auth?sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&clientRedirectURL=' +
				encodeURIComponent(currentURL);

			if (redirectAuthFunction) {
				redirectURL += '&type=redirectFunction';
				redirectAuthFunction(redirectURL, function () {
					// Wrapped in a timeout because sometimes the read from firebase happens before the data is available.
					// Todo figure out a better way than a delay here to ensure the token is ready to be fetched
					window.setTimeout(() => {
						updateToken(userID, sourceID, function (result) {
							check(result, sourceID, function (result) {
								callback(result);
							});
						});
					}, 500);
				});
			} else {
				redirectURL += '&type=redirect';
				window.location.href = redirectURL;
			}
		}

		function authPopup(sourceID, userID, callback) {
			var popup = window.open(
				baseURL +
					'api/office/auth?type=popup&sourceID=' +
					encodeURIComponent(sourceID) +
					'&userID=' +
					encodeURIComponent(userID),
				'',
				'width=500, height=690'
			);
			popup.onload = function () {
				// Check for an error loading the url
				var documentTitle = popup.document.title;
				if (documentTitle === '502 Bad Gateway') {
					popup.close();
					errorReporter(502);
				}
			};

			popoverCheck(popup, callback);

			function popoverCheck(popupWindow, callback) {
				window.setTimeout(function () {
					var urlParams = {};
					var returnParams = {};
					try {
						urlParams = getURLParams(popupWindow.location.search);
					} catch (error) {
						// Couldn't get url, probably because the popup is currenty showing a office domain
						// Nothing to do here as we will wait for further action either window close or redirect back to our domain
					}
					if (popupWindow && popupWindow.closed) {
						// If window was closed run the callback with no result
						callback();
					} else if (urlParams.access_token) {
						returnParams.access_token = urlParams.access_token;
						returnParams.expiry_date =
							new Date().getTime() +
							Number(urlParams.expires_in) * 1000;

						popupWindow.close();

						applyToken(userID, sourceID, returnParams, null);

						callback(returnParams);
					} else {
						popoverCheck(popupWindow, callback);
					}
				}, 250);
			}
		}

		function salesforceMobileAuthRedirect(sourceID, userID, callback) {
			var hidden = false;
			var url =
				baseURL +
				'api/office/auth?type=popup&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&forceClose=true';

			//Add a listener for popup window closing
			document.addEventListener('visibilitychange', onchange);

			//Open new auth window
			fbk.publish('dbk.navigate', {url: url, new: true});

			function onchange(e) {
				hidden = !hidden;
				if (!hidden) {
					document.removeEventListener('visibilitychange', onchange);
					auth(userID, sourceID, true, false, callback);
				}
			}
		}
	}

	function updateToken(userID, sourceID, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		ajaxRequest({
			url: baseURL + 'api/office/token',
			type: 'GET',
			params: params,
			onSuccess: function (tokens) {
				applyToken(userID, sourceID, tokens, callback);
			},
			onError: function (error) {
				if (callback) {
					callback(false);
				}
			},
		});
	}

	function applyToken(userID, sourceID, tokens, callback) {
		var fetchMinutesBeforeExpires = 5;
		var now = new Date().getTime();

		var token;
		var expires;

		var updateTimeout;

		// Clear any pending timeouts
		clearTimeout(tokenWatcher);

		if (tokens && !tokens.expiry_date && tokens.expires_in) {
			tokens.expiry_date =
				new Date().getTime() + Number(tokens.expires_in) * 1000;
		}

		if (
			tokens &&
			tokens.access_token &&
			tokens.expiry_date &&
			!tokens.error
		) {
			token = tokens.access_token;
			expires = Number(tokens.expiry_date);

			updateTimeout =
				expires - now - 1000 * 60 * fetchMinutesBeforeExpires;

			// Remove old token from user token map if it exists
			if (userTokenMap[settings.token]) {
				delete userTokenMap[settings.token];
			}

			// Set the token to global settings
			settings.token = token;
			settings.tokenexpires = now + (expires - 60) * 1000;

			userTokenMap[token] = {
				token: token,
				expires: expires,
				userID: userID,
				sourceID: sourceID,
			};

			settings.config.immediate = true;

			// Set a timeout so we can update the token before it expires
			tokenWatcher = window.setTimeout(function () {
				updateToken(userID, sourceID);
			}, updateTimeout);
		} else {
			// Set to true here as we'll be recalling without re-loading the page
			settings.config.immediate = true;
		}
		if (callback) {
			callback(token);
		}
	}

	// callback for checking our silent try
	function check(result, sourceID, callback) {
		var callbackRan;
		if (result && !result.error) {
			// we're already authorized in.
			userReq(includeGroupCalendars[sourceID], function (profile) {
				userProfile = profile;
				authCallbackCheck();
			});
			if (preventUserListFor[sourceID]) {
				userList = [];
				authCallbackCheck();
			} else {
				getUsers(function (users) {
					userList = users;
					authCallbackCheck();
				});
			}
		} else {
			//set settings.config.immediate to false so next check brings up the pop-over
			//false callback should trigger showing the auth button
			settings.config.immediate = false;
			if (callback) {
				callback(false);
			}

			return false;
		}

		function authCallbackCheck() {
			if (userProfile && userList && !callbackRan) {
				callbackRan = true;
				callback(userProfile);
			}
		}
	}

	// get user info
	function userReq(includeGroups, callback) {
		let params = {
			access_token: settings.token,
		};
		if (includeGroups) {
			params.$expand = 'memberOf'; // includes array of groups the member is in
		}

		ajaxRequest({
			url: 'https://graph.microsoft.com/v1.0/me/',
			type: 'GET',
			params: params,
			onSuccess: function (response) {
				if (callback) {
					callback(response);
				}
			},
			onError: function (error, status) {
				// Assume if this is a bad request that it's a user account
				// User accounts don't support members
				if (status === 400) {
					userReq(false, callback);
				}
				console.log(error);
			},
		});
	}

	// Not used but left as a reference if we need to batch any requests together. Individual request limits still apply so this doesn't seem usefull
	function batchRequests(request, callback) {
		var url = 'https://graph.microsoft.com/v1.0/$batch';
		var params = {
			access_token: settings.token,
		};

		request = {
			requests: [
				{
					id: '1',
					method: 'GET',
					url: '/me',
				},
				{
					id: '2',
					method: 'GET',
					url: '/users',
				},
			],
			headers: {
				'Content-Type': 'application/json',
			},
		};

		ajaxRequest({
			url: url,
			type: 'POST',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				console.log('response for batch', response);
				// processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});
	}

	function getCalendar(calendarID, isGroup, callback) {
		const pathString = isGroup
			? `groups/${calendarID}/calendar`
			: 'me/calendar';
		const url = `https://graph.microsoft.com/v1.0/${pathString}`;

		const params = {
			access_token: settings.token,
			$top: maxPageValue,
		};

		ajaxRequest({
			url: url,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processResponse(data) {
			if (callback) {
				callback(data.value);
			}
		}
	}

	function calendarList(callback) {
		const url = 'https://graph.microsoft.com/v1.0/me/calendars';
		const params = {
			access_token: settings.token,
			$top: maxPageValue,
		};

		ajaxRequest({
			url: url,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processResponse(data) {
			let groupCalendars = [];
			if (userProfile?.memberOf) {
				for (let i = 0; i < userProfile.memberOf.length; i++) {
					if (
						userProfile.memberOf[i].groupTypes?.includes('Unified')
					) {
						// Create calendar data from group
						groupCalendars.push({
							id: userProfile.memberOf[i].id,
							name: userProfile.memberOf[i].displayName,
							isGroup: true,
							color: userProfile.memberOf[i].color || 'auto',
						});
					}
				}
			}
			// Merge any group calendars with user calendars and run callback
			callback([...data.value, ...groupCalendars]);
		}
	}

	function eventList(
		calendarId,
		fieldMap,
		fetchID,
		callback,
		params,
		instances,
		isGroup,
		retryCount
	) {
		lastFetchID = fetchID;
		// Avoid microsoft concurrent request limit (4)
		if (activeEventQueries > 3) {
			window.setTimeout(function () {
				// Only continue if the fetchid is the same. If it's different that means dates have been navigated
				if (lastFetchID === fetchID) {
					eventList(
						calendarId,
						fieldMap,
						fetchID,
						callback,
						params,
						instances,
						isGroup,
						retryCount
					);
				}
			}, 100);
			return;
		}
		activeEventQueries++;

		let url;
		const pathString = isGroup ? 'groups' : 'me/calendars';

		if (instances) {
			url = `https://graph.microsoft.com/v1.0/${pathString}/${calendarId}/events`;
		} else {
			url = `https://graph.microsoft.com/v1.0/${pathString}/${calendarId}/calendarview`;
		}

		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		params['$top'] = maxPageValue;

		ajaxRequest({
			url: url,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				activeEventQueries--;
				checkResult(response);
			},
			onError: function (error) {
				activeEventQueries--;
				console.log(error);
				checkResult({error: error});
			},
		});

		//internal call back to get more results.
		function checkResult(result) {
			var items = [];
			processResult(result);

			function processResult(result) {
				//Exit with callback if we have an error
				if (result.error) {
					if (callback) {
						callback(result);
					}
					return;
				}
				if (!result.value) {
					result.value = items;
					callback(result);
					return;
				}

				for (var i = 0; i < result.value.length; i++) {
					items.push(result.value[i]);
				}

				if (result['@odata.nextLink']) {
					// we have more results to get

					// Clear out all params and set the accsss token again
					params = {
						access_token: settings.token,
					};

					ajaxRequest({
						url: result['@odata.nextLink'],
						type: 'GET',
						params: params,
						onSuccess: function (response) {
							processResult(response);
						},
						onError: function (error) {
							console.log(error);
						},
					});
				} else {
					result.value = items;
					callback(result); // external callback
				}
			}
		}
	}

	function getUsers(callback, removeTop) {
		var url = 'https://graph.microsoft.com/v1.0/users';

		var params = {
			access_token: settings.token,
		};

		params['$select'] = 'mail,displayName';

		if (!removeTop) {
			params['$top'] = maxPageValue;
		}

		ajaxRequest({
			url: url,
			type: 'GET',
			params: params,
			retryCheck: retryCheck,
			preventErrorReporter: true,
			retryLimit: 0,
			onSuccess: function (response) {
				checkResult(response);
			},
			onError: function (error, status) {
				// Personal accounts can't get a user list because they don't have one
				if (!removeTop) {
					getUsers(callback, true);
				} else {
					console.log(error);
				}
			},
		});

		//internal call back to get more results.
		function checkResult(result) {
			var items = [];
			processResult(result);

			function processResult(result) {
				//Exit with callback if we have an error
				if (result.error) {
					if (callback) {
						callback(result);
					}
					return;
				} else if (!result.value) {
					// As of 2023-07-07 Microsoft is returning a malformed query
					// for personal MS365 accounts. There aren't supposed to be
					// any users but the json parse is failing in the ajax call
					// so we are returning an empty array
					if (callback) {
						callback([]);
					}
					return;
				}

				for (var i = 0; i < result.value.length; i++) {
					items.push(result.value[i]);
				}

				if (result['@odata.nextLink']) {
					// we have more results to get

					// Clear out all params and set the accsss token again
					params = {
						access_token: settings.token,
					};

					ajaxRequest({
						url: result['@odata.nextLink'],
						type: 'GET',
						params: params,
						onSuccess: function (response) {
							processResult(response);
						},
						onError: function (error) {
							console.log(error);
						},
					});
				} else {
					result.value = items;
					callback(result.value); // external callback
				}
			}
		}
	}

	function getFieldMap() {
		return {
			titleEdit: 'subject',
		};
	}

	function getValidFields() {
		return {
			attendees: true,
			body: true,
			categories: true,
			end: true,
			hideAttendees: true,
			importance: true,
			isAllDay: true,
			isOnlineMeeting: true,
			isReminderOn: true,
			location: true,
			locations: true,
			onlineMeetingProvider: true,
			recurrence: true,
			reminderMinutesBeforeStart: true,
			responseREquested: true,
			sensitivity: true,
			showAs: true,
			start: true,
			subject: true,
		};
	}
	function mutateEditRequest(request) {
		var requestOutput = {};
		var validFields = getValidFields();
		for (var property in request) {
			if (validFields[property]) {
				requestOutput[property] = request[property];
			}
		}

		return requestOutput;
	}

	function eventQuery(url, type, params, request, callback) {
		ajaxRequest({
			url: url,
			type: type,
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				callback(response);
			},
			onError: function (error) {
				console.log(error);
				callback({error: error});
			},
		});
	}

	function createEvent(calendarId, request, params, callback, isGroup) {
		const pathString = isGroup ? 'groups' : 'me/calendars';
		const url = `https://graph.microsoft.com/v1.0/${pathString}/${calendarId}/events`;
		const requestObject = mutateEditRequest(request);

		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		eventQuery(url, 'POST', params, requestObject, checkResult);

		function checkResult(result) {
			//Exit with callback if we have an error
			if (result.error) {
				if (callback) {
					callback(result);
				}
				return;
			}

			if (callback) {
				callback(result);
			}
		}
	}

	function updateEvent(
		calendarId,
		eventId,
		request,
		params,
		callback,
		isGroup
	) {
		const pathString = isGroup ? 'groups' : 'me/calendars';
		const url = `https://graph.microsoft.com/v1.0/${pathString}/${calendarId}/events/${eventId}`;
		const requestObject = mutateEditRequest(request);

		//add token to params
		if (!params) {
			params = {access_token: settings.token};
		} else {
			params.access_token = settings.token;
		}

		// params['$top'] = maxPageValue;

		eventQuery(url, 'PATCH', params, requestObject, checkResult);

		function checkResult(result) {
			//Exit with callback if we have an error
			if (result.error) {
				if (callback) {
					callback(result);
				}
				return;
			}

			if (callback) {
				callback(result);
			}
		}
	}

	function deleteEvent(calendarId, eventId, callback, isGroup) {
		const pathString = isGroup ? 'groups' : 'me/calendars';
		const url = `https://graph.microsoft.com/v1.0/${pathString}/${calendarId}/events/${eventId}`;

		//add token to params
		const params = {access_token: settings.token};

		eventQuery(url, 'DELETE', params, null, checkResult);

		function checkResult(result) {
			//Exit with callback if we have an error
			if (result.error) {
				if (callback) {
					callback(result);
				}
				return;
			}

			if (callback) {
				callback(result);
			}
		}
	}

	function getEvent(calendarId, eventId, callback) {
		var url =
			'https://graph.microsoft.com/v1.0/me/calendars/' +
			calendarId +
			'/events/' +
			eventId;

		// var url = 'https://graph.microsoft.com/v1.0/me/calendars/' + calendarId + '/events/' + eventId + '/instances';

		// ?startDateTime={start_datetime}&endDateTime={end_datetime}

		//add token to params
		var params = {access_token: settings.token};

		eventQuery(url, 'GET', params, null, checkResult);

		function checkResult(result) {
			//Exit with callback if we have an error
			if (result.error) {
				if (callback) {
					callback(result);
				}
				return;
			}

			if (callback) {
				callback(result);
			}
		}
	}

	function getEventInstances(
		calendarId,
		eventId,
		rangeStart,
		rangeEnd,
		callback
	) {
		var url =
			'https://graph.microsoft.com/v1.0/me/calendars/' +
			calendarId +
			'/events/' +
			eventId +
			'/instances';

		//add token to params
		var params = {access_token: settings.token};

		if (rangeStart) {
			params['startDateTime'] = rangeStart;
		}

		if (rangeEnd) {
			params['endDateTime'] = rangeEnd;
		}

		params['$top'] = maxPageValue;

		eventQuery(url, 'GET', params, null, checkResult);

		//internal call back to get more results.
		function checkResult(result) {
			var items = [];
			processResult(result);

			function processResult(result) {
				//Exit with callback if we have an error
				if (result.error) {
					if (callback) {
						callback(result);
					}
					return;
				}
				if (!result.value) {
					result.value = items;
					callback(result);
					return;
				}

				for (var i = 0; i < result.value.length; i++) {
					items.push(result.value[i]);
				}

				if (result['@odata.nextLink']) {
					// we have more results to get

					// Clear out all params and set the accsss token again
					params = {
						access_token: settings.token,
					};

					ajaxRequest({
						url: result['@odata.nextLink'],
						type: 'GET',
						params: params,
						onSuccess: function (response) {
							processResult(response);
						},
						onError: function (error) {
							console.log(error);
						},
					});
				} else {
					result.value = items;
					callback(result); // external callback
				}
			}
		}
	}

	function getTasks(callback) {
		var url = 'me/tasks';
		queryOffice(url, callback);
	}

	function isUser(emailAddress, name) {
		if (!userList) {
			return false;
		}

		for (var i in userList) {
			if (
				(emailAddress && userList[i].mail === emailAddress) ||
				(name && userList[i].displayName === name)
			) {
				return userList[i];
			}
		}
		return false;
	}

	function userLocationByName(name) {
		// We want to get away from fetching the user list on load so we should do this another way
		// for ( var i in userList ) {
		// 	if(userList[i].displayName === name) {
		// 		return userList[i].officeLocation;
		// 	}
		// }
		// return false;
	}

	function viewInOffice(event, rootScope, mode) {
		var top = window.screenY + 22;
		var left = window.screenX + 22;
		var webId = event.webLinkId;
		var eventId = event.eventID;
		var newEvent;

		var getEvent = event.source.getOneEvent;

		var url =
			mode === 'edit'
				? 'https://outlook.office.com/owa/?ItemID=' +
				  webId +
				  '&exvsurl&viewmodel=IComposeCalendarItemViewModelFactory'
				: 'https://outlook.office.com/owa/?ItemID=' +
				  webId +
				  '&exvsurl=1&viewModel=ICalendarItemDetailsViewModelFactory';

		var popup = window.open(
			url,
			'ModalPopUp',
			'toolbar=no,' +
				'scrollbars=no,' +
				'location=no,' +
				'statusbar=no,' +
				'menubar=no,' +
				'resizable=0,' +
				'width=1024,' +
				'height=800,' +
				'left = ' +
				left +
				',' +
				'top = ' +
				top
		);

		if (mode === 'edit') {
			//begin timer to watch for the edit window to close
			rootScope.$broadcast('closePopovers', false);
			var interval = setInterval(refresh, 100);
		}

		function refresh() {
			if (!popup.self) {
				clearInterval(interval);
				//make call to updated office event
				getEvent(eventId, resultcallback, event.schedule);
			}
		}

		function resultcallback(eventObject) {
			newEvent = eventObject[0];
			setTimeout(updateEvent, 100);
			rootScope.$broadcast('closePopovers', false);
		}

		function updateEvent() {
			var newStart = $.fullCalendar.moment(newEvent.start);
			var newEnd = $.fullCalendar.moment(newEvent.end).add(1, 'd');
			newStart.stripTime();
			newEnd.stripTime();
			event.start = newStart;
			event.end = newEnd;
			for (var property in newEvent) {
				if (property !== 'end' && property !== 'start') {
					event[property] = newEvent[property];
				}
			}
			var element = $('.calendar');
			element.fullCalendar('updateEvent', event);
		}
	}

	function updateCalendar(calendarId, request, callback, retryCount) {
		//add token to params
		var params = {access_token: settings.token};

		ajaxRequest({
			url:
				'https://graph.microsoft.com/v1.0/me/calendars/' +
				encodeURIComponent(calendarId),
			type: 'PATCH',
			params: params,
			data: request,
			retryCheck: retryCheck,
			onSuccess: function (response) {
				processResponse(response);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		//Process responce result and activate retry if necessary
		function processResponse(result) {
			if (callback) {
				callback(result);
			}
		}
	}

	function deauthorize(userID, sourceID, switchAccount, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		if (switchAccount) {
			processDeauthorize(callback);
			return;
		}

		ajaxRequest({
			url: baseURL + 'api/office/token',
			type: 'DELETE',
			params: params,
			onSuccess: function (response) {
				processDeauthorize(callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processDeauthorize(callback) {
			if (callback) {
				settings.token = null;
				if (!switchAccount) {
					settings.config.immediate = false;
				}
				callback();
			}
		}
	}

	function retryCheck(result) {
		if (!result) {
			return;
		}
		var error = result.error;
		var errorResult;
		var reAuth;
		var message;
		var preventDeauth;

		if (error) {
			reAuth =
				error.code === 'InvalidAuthenticationToken' ||
				error.code === 'Authorization_RequestDenied';
			message = error.message;
			preventDeauth = !reAuth;

			errorResult = {
				reAuth: reAuth,
				preventDeauth: preventDeauth,
				code: error ? error.code : null,
				message: message ? message : '',
			};
		}

		return errorResult;
	}

	/*############PRIVATE FUNCTIONS###############*/

	function queryOffice(query, callback, beta) {
		query = encodeURIComponent(query);
		var url = settings.phpPath + '?query=true&url=' + query;
		if (beta) {
			url += '&beta=true';
		}
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = JSON.parse(request.responseText);
				callback(result);
			}
		};
		request.send(settings.access_token);
	}

	function patchOffice(url, body, callback) {
		//add our token to the JSON, we'll remove in the PHP page
		var part1 = btoa(settings.access_token);
		var data = part1 + '.' + JSON.stringify(body);
		url = settings.phpPath + '?edit=true&url=' + encodeURIComponent(url);
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = JSON.parse(request.responseText);
				callback(result);
			}
		};
		request.send(data);
	}

	function deleteOffice(url, callback) {
		//add our token to the JSON, we'll remove in the PHP page
		var data = btoa(settings.access_token);
		url = settings.phpPath + '?delete=true&url=' + encodeURIComponent(url);
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = JSON.parse(request.responseText);
				callback(result);
			}
		};
		request.send(data);
	}

	function createOffice(url, body, callback) {
		//add our token to the JSON, we'll remove in the PHP page
		var part1 = btoa(settings.access_token);
		var data = part1 + '.' + JSON.stringify(body);
		url = settings.phpPath + '?create=true&url=' + encodeURIComponent(url);
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = JSON.parse(request.responseText);
				callback(result);
			}
		};
		request.send(data);
	}

	function refreshAccessToken(callback) {
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', settings.phpPath + '?refresh=true');
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = request.responseText;
				result = JSON.parse(result);
				if (result.access_token) {
					settings.access_token = result.access_token;
					callback(request.responseText);
				} else {
					callback(false);
				}
			}
		};
		request.send(settings.refresh_token);
	}

	function getLoginUrl(callback) {
		var result;
		var request = new XMLHttpRequest();
		request.open('GET', settings.phpPath + '?loginUrl=true');
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				settings.loginUrl = request.responseText;
				callback(false); //return false to the top so that the log-in button is initialized
			}
		};
		request.send(null);
	}

	function ajaxRequest(options, retryCount) {
		// parameter object options:
		//{url, type, params, data, retryCheck, preventErrorReporter, onSuccess, onError}

		var responseResult;
		var paramList = [];
		var data;
		var type;
		var retryCheckResult;
		var url = options.url;
		var code;
		var message;
		var preventDeauth;
		var retryLimit = options.hasOwnProperty('retryLimit')
			? options.retryLimit
			: settings.retryLimit;

		if (!options) {
			return;
		}

		if (!retryCount) {
			retryCount = 0;
		}

		// Build parameter string and append to url
		if (options.params) {
			for (var param in options.params) {
				if (param !== 'access_token') {
					paramList.push(
						encodeURIComponent(param) +
							'=' +
							encodeURIComponent(options.params[param])
					);
				}
			}

			url += paramList.length ? '?' + paramList.join('&') : '';
		}

		type = options.type ? options.type.toUpperCase() : 'GET';

		var xhr = new XMLHttpRequest();
		xhr.open(type, url);

		if (options.params && options.params.access_token) {
			xhr.setRequestHeader(
				'Authorization',
				'Bearer ' + options.params.access_token
			);
		}

		if (options.data) {
			try {
				data = JSON.stringify(options.data);
				xhr.setRequestHeader('Content-Type', 'application/json');
			} catch (error) {
				// Could perform an action if options is not an object
				data = options.data;
				xhr.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded'
				);
			}
		}

		xhr.onreadystatechange = function (e) {
			if (xhr.readyState == 4) {
				try {
					responseResult = JSON.parse(xhr.response);
				} catch (error) {
					responseResult = xhr.response;
				}

				if (xhr.status == 400) {
					// Look for 400 (bad request) specifically because we don't want to retry this error
					options.onError(xhr.response, xhr.status, true);
				} else if (
					xhr.status == 200 ||
					xhr.status == 201 ||
					(type === 'DELETE' && xhr.status == 204)
				) {
					if (
						options.onSuccess &&
						(xhr.response ||
							(type === 'DELETE' && xhr.status == 204))
					) {
						retryCheckResult = options.retryCheck
							? options.retryCheck(responseResult)
							: null;

						if (retryCheckResult && retryCount < retryLimit) {
							if (retryCheckResult.reAuth) {
								authAndRetry();
							} else {
								retry();
							}

							return;
						}

						options.onSuccess(responseResult);
					}
				} else {
					retryCheckResult = options.retryCheck
						? options.retryCheck(responseResult)
						: null;
					preventDeauth =
						retryCheckResult && retryCheckResult.preventDeauth;

					// Check for an invalid token response and attempt to renew the token. Limit to 3 retries
					if (
						((retryCheckResult && retryCheckResult.reAuth) ||
							(options.params.access_token &&
								!retryCheckResult &&
								(Number(xhr.status) === 401 ||
									Number(xhr.status) === 403))) &&
						retryCount < retryLimit &&
						userTokenMap[settings.token]
					) {
						authAndRetry();
					} else if (retryCheckResult && retryCount < retryLimit) {
						retry();
					} else if (options.onError) {
						if (
							(options.preventErrorReporter && !preventDeauth) ||
							!options.preventErrorReporter
						) {
							errorReporter(
								xhr.status,
								retryCheckResult && retryCheckResult.message,
								preventDeauth
							);
						}
						options.onError(
							retryCheckResult || xhr.response,
							xhr.status,
							preventDeauth
						);
					}
				}
			}
		};
		xhr.send(data);

		function authAndRetry() {
			window.setTimeout(function () {
				updateToken(
					userTokenMap[settings.token].userID,
					userTokenMap[settings.token].sourceID,
					function (token) {
						options.params.access_token = token;
						ajaxRequest(options, retryCount + 1);
					}
				);
			}, settings.retryWait);
		}

		function retry() {
			window.setTimeout(
				function () {
					ajaxRequest(options, retryCount + 1);
				},
				settings.retryWait * retryCount + 1
			);
		}
	}

	function getURLParams(url) {
		if (!url) {
			return {};
		}
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = url.substring(1);

		var urlParams = {};
		while ((match = search.exec(query))) {
			urlParams[decode(match[1])] = decode(match[2]);
		}
		return urlParams;
	}

	function isStandalone() {
		var standalone =
			'standalone' in window.navigator && window.navigator.standalone;
		return standalone;
	}

	function isIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	function isMobile() {
		return isIos() || isAndroid() || isWindowsPhone();
	}

	function isIos() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipod|ipad/.test(userAgent) && !window.MSStream;
	}

	function isAndroid() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /android/i.test(userAgent);
	}

	function isWindowsPhone() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /windows phone/i.test(userAgent);
	}
})(
	//settings session/defaults storage
	{
		retryLimit: 2,
		phpPath: '/php/office365/office-connect.php',
		config: {
			immediate: true, //set to true to attempt token retrieval without the pop-up
		},
	}
);
